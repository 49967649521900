import { filename } from 'pathe/utils';
import type { GlobResult } from '~/types/composables/useAssetImages';

export const useAssetsImages = () => {
  const glob: GlobResult = import.meta.glob(
    '~/assets/images/**/*.(svg|png|jpg|jpeg|gif)',
    { eager: true },
  );

  return Object.fromEntries(
    Object.entries(glob).map(([key, value]) => [filename(key), value.default]),
  );
};
